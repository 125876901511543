import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from '../utils/articleUtil';
import {Basic, Facebook, Twitter} from '../components/SEO';
import {
  ArticleList,
  ArticlesComplexLayout,
  ArticlesHorizontalLayout,
  CategoryHeader,
  track
} from '@cg-squad/ui-components';
import mixpanel from '../constants/mixpanel';
import {categoryPageAds} from '../utils/adUnits';
import {isMobile} from 'react-device-detect';
import {metadata} from '../../config';
import {addTimeout} from '../utils/timeManager';
import {Helmet} from 'react-helmet';
import SeeMore from '../components/buttons/SeeMore';

const CategoryPage = props => {
  setStreamData({
    category: props.pageContext.title,
    subCategory: undefined,
    article: undefined,
    author: undefined
  });

  const seo = {
    title: props.pageContext.title,
    description: `Discover all of our ${props.pageContext.title} articles, written by our knowledgeable team of writers.`
  };
  const url = `${metadata.url}/${props.pageContext.slug}`;
  const [allArticles] = useState([...props.data.pillarArticles.nodes]);

  props.data.subCategoryArticles.nodes.forEach(article => {
    if (!props.data.pillarArticles.nodes.find(a => a.id === article.id)) {
      allArticles.push(article);
    }
  });

  const articleList = allArticles.length > 13 ? allArticles.slice(13) : allArticles;
  const [displayedSubCategoryArticles, setDisplayedSubCategoryArticles] = useState(articleList.slice(0, 10));

  useEffect(() => {
    track(mixpanel.MIXPANEL_PAGE_VIEW.CATEGORY);
    addTimeout(categoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
      {key: 'category', value: props.pageContext.slug}
    ]);
  }, []);

  const seeMore = () => {
    setDisplayedSubCategoryArticles(articleList.slice(0, displayedSubCategoryArticles.length + 10));
  };

  return (
    <Layout relativePath={props.path?.replace('/', '')} path={props.location.pathname}>
      <Basic seo={seo}/>
      <Twitter seo={seo} url={url}/>
      <Facebook seo={seo} url={url}/>
      <Helmet>
        <link rel="canonical" href={url}/>
      </Helmet>
      <main className="wrapper font-arial leading-snug" data-datocms-noindex>
        <CategoryHeader category={{title: props.pageContext.title, slug: props.pageContext.slug}}/>
        {props.data.subCategoryArticles.nodes.length > 3 &&
          <ArticlesComplexLayout articles={allArticles.slice(0, 9)}/>}
        {props.data.subCategoryArticles.nodes.length > 9 &&
          <ArticlesHorizontalLayout articles={allArticles.slice(9, 13)}/>}
        <div className="ad-container my-4">
          <div className="advertisement-text">Advertisement</div>
          <div id="at_category_incontent1"/>
        </div>
        {displayedSubCategoryArticles?.length > 0 &&
          <ArticleList articles={displayedSubCategoryArticles}/>}
        {displayedSubCategoryArticles.length < articleList.length &&
          <SeeMore onSeeMoreClick={seeMore}/>}
        <div className="ad-container my-4">
          <div className="advertisement-text">Advertisement</div>
          <div id="at_category_incontent2"/>
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
    query ArticlesCategoryWise($subCategories: [String], $domain: String) {
        pillarArticles: allDatoCmsArticle(filter: {
            category: {elemMatch: {originalId: {in: $subCategories}}}, 
            website: {elemMatch: {name: {eq: "at"}}}, 
            isCategoryPinned: {eq: true}, 
            slug: {ne: "how-to-get-more-from-asian-holidays-2"},
            noIndex: {ne: true},
            domains: {elemMatch: {name: {eq: $domain}}}}
            sort: {publishedAt: DESC}
        ) {
            nodes {
                ...ArticleFieldsPlugin    
            }
        }
        subCategoryArticles: allDatoCmsArticle(filter: {
            category: {elemMatch: {originalId: {in: $subCategories}}}, 
            website: {elemMatch: {name: {eq: "at"}}}, 
            slug: {ne: "how-to-get-more-from-asian-holidays-2"},
            noIndex: {ne: true},
            domains: {elemMatch: {name: {eq: $domain}}}}
            sort: {publishedAt: DESC}
            limit: 100
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`;

export default CategoryPage;
